/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Components
import CustomLink from 'components/shared/CustomLink'
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import CallToAction from 'components/CallToAction'
import MoreInformation from 'components/MoreInformation'
import Arrangements from 'components/Arrangements'
import Gallery from 'components/Gallery'

// Elements
import ContentDefault from 'components/elements/ContentDefault'
import HeroDefault from 'components/elements/HeroDefault'
import MoodImage from 'components/elements/MoodImage'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ContainerSmall from 'components/elements/ContainerSmall'

const PartiesImage = styled(BackgroundImage)`
  background-size: contain;
  background-position: top;

  @media (min-width: 992px) {
    width: 550px;
    height: 400px;
  }

  @media (max-width: 991px) {
    width: 450px;
    height: 250px;
  }

  @media (max-width: 575px) {
    height: 200px;
  }
`

const PartiesTitle = styled.h2`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.ml};
  font-weight: ${props => props.theme.font.weight.m};
  letter-spacing: 1px;
  text-transform: uppercase;
`

const PartiesParty = styled(CustomLink)`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.l};
  font-weight: ${props => props.theme.font.weight.m};
  
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 16px 16px 0;

  &:nth-of-type(odd) {
    color: ${props => props.theme.color.text.main};
  }

  &:nth-of-type(even) {
    color: #AEAEAE;
  }

  &:hover {
    color: ${props => props.theme.color.text.contrast};
    text-decoration: underline;
  }
`

const PartiesDivider = styled.span`
  background-color: ${props => props.theme.color.dark};
  height: 2px;
  width: 10px;
  margin: 0 16px 7px 0;
  display: inline-block;
`

const HexagonTitle = styled.h3`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size.l};
  font-family: ${props => props.theme.font.family.secondary};
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all .1s;
`

const Hexagon = styled(CustomLink)`
  position: relative;

  &:hover {
    & ${HexagonTitle} {
      font-size: ${props => parseFloat(props.theme.font.size.l.replace('px')) + 2}px;
      text-decoration: underline;
    }
  }

  @media (min-width: 992px) {
    width: 230px;
    height: 260px;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 170px;
  }

  @media (max-width: 400px) {
    width: 175px;
    height: 198px;
  }
`

const HexagonWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HexagonImage = styled(BackgroundImage)`
  background-size: contain;
  height: 100%;
  width: 100%;
`

const ArrangementSection = styled.section`
  background-color: ${props => props.theme.color.dark};
`

const ArrangementTitle = styled.h2`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.light};
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      wpId: wordpress_id
      title
      path
      content
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerContent: banner_content

        callToActionRelation: cta_relation

        contactRelation: contact_relation

        partiesImage: parties_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 550) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        partiesTitle: parties_title
        partiesRelation: parties_relations {
          pageId: pagina
        }

        galleryImages: gallery_images {
          wpId: wordpress_id
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
        }

        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
    pages: allWordpressPage {
      edges {
        node {
          wpId: wordpress_id
          title
          path
        }
      }
    }
    halls: allWordpressPage(filter: {parent_element: {wordpress_id: {eq: 9}}}) {
      edges {
        node {
          path
          title
          wpId: wordpress_id
          acf {
            thumbnailImage: thumbnail_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 230) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    pages,
    page: { wpId, content, title, path, yoastMeta, acf },
    halls
  },
}) => {
  const filteredHalls = halls.edges.filter(({ node: { wpId: pageId } }) => pageId !== wpId)

  return (
    <Layout headerTop>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroDefault title={acf.bannerContent} image={acf.backgroundImage} />
      <div className="container">
        <section className="row">
          <div className="col-lg-7 py-5">
            <TitleSub>{title}</TitleSub>
            <BrandTitle />
            <ContentDefault className="mt-5" content={content} />
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-4">
            <CallToAction id={acf.callToActionRelation} />
          </div>
        </section>
      </div>

      <div className="pt-5">
        <MoreInformation id={acf.contactRelation} className="mt-5" />
      </div>

      {acf.galleryImages && (
        <section className="py-5">
          <Gallery className="pt-5" images={acf.galleryImages} />
        </section>
      )}

      <ArrangementSection className="mt-5 pt-5">
        <div className="text-center">
          <ArrangementTitle>Arrangementen</ArrangementTitle>
        </div>
        <Arrangements inside />
      </ArrangementSection>

      <section className="container pb-2">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center">
            <PartiesImage
              fluid={acf.partiesImage.localFile.childImageSharp.fluid}
            />
          </div>
          <div className="col-lg-6 text-center pt-3 pt-lg-5">
            <PartiesTitle>{acf.partiesTitle}</PartiesTitle>
            <div className="mt-3 mt-lg-5 px-lg-5">
              <div className="px-lg-5">
                {acf.partiesRelation.map(({ pageId }, index) => {
                  const {
                    node: { title: pageTitle, path: pagePath },
                  } = pages.edges.find(({ node: { wpId: partyPageId } }) => partyPageId === pageId)

                  return (
                    <>
                      {index > 0 && (
                        <PartiesDivider />
                      )}
                      <PartiesParty key={pageId} to={pagePath}>
                        {pageTitle}
                      </PartiesParty>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 mt-5 mt-lg-0">
        <ContainerSmall>
          <div className="row pb-5">
            <div className="col-lg-6 d-lg-flex justify-content-center align-items-center">
              <div>
                <TitleSub>
                  Ontdek andere zalen
                </TitleSub>
                <BrandTitle />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center mt-3 mt-lg-0">
              {filteredHalls.map(({ node: { wpId: pageId, path: hallPath, title: hallTitle, acf: { thumbnailImage } } }) => (
                <Hexagon key={pageId} to={hallPath} className="mx-2">
                  <HexagonImage fluid={thumbnailImage.localFile.childImageSharp.fluid} />
                  <HexagonWrapper>
                    <HexagonTitle>{hallTitle}</HexagonTitle>
                  </HexagonWrapper>
                </Hexagon>
              ))}
            </div>
          </div>
        </ContainerSmall>
      </section>

      {acf.moodImage && <MoodImage image={acf.moodImage} />}
    </Layout>
  )
}

export default PageTemplate
